import Header from "./homepage/header";
import Intro from "../pages/homepage/intro";
import ShkaryLore from "./homepage/lore";
// import Utility from "./homepage/utility";
import Artwork from "./homepage/artwork";
import LaunchPad from "./homepage/launchpad";
import Artist from "./homepage/artist";
import ShkaryThings from "./homepage/shkarythings";
import Footer from "./homepage/footer";
import "./homepage.css";

function Homepage() {
  return (
    <>
      <Header />
      <Intro />
      <ShkaryLore />
      {/* <Utility /> */}
      <Artwork />
      <LaunchPad />
      <Artist />
      <ShkaryThings />
      <Footer />
    </>
  );
}

export default Homepage;
