import React, { useState, useEffect, useRef } from "react";
import * as Scroll from "react-scroll";
import styled from "styled-components";
import Logo from "../../logo.svg";
import LogoDark from "../../logo-dark.svg";
import { ReactComponent as TwitterIcon } from "../../assets/img/twitter.svg";
import { ReactComponent as DiscordIcon } from "../../assets/img/discord.svg";
import { ReactComponent as MobileMenuIcon } from "../../assets/img/mobile-menu.svg";
import { ReactComponent as MobileMenuCloseIcon } from "../../assets/img/close.svg";

function Header() {
  let Link = Scroll.Link;
  const headerRef = useRef();
  const [scrolled, setScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const StyledGap = styled.div`
    display: ${scrolled ? "block" : "block"};
    height: ${scrolled ? "8.333vw" : "0"};
    width: 100%;
    @media (max-width: 600px) {
      height: ${scrolled ? "25vw" : "0"};
    }
`;
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > headerRef.current.clientHeight) {
      setScrolled(true);
    }
    else {
      setScrolled(false);
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.body.classList.toggle('lock-scroll', isMobileMenuOpen);
    return () => {
      document.body.classList.remove('lock-scroll');
    }
  }, [isMobileMenuOpen]);
  let navbarClasses = ['header'];
  if (scrolled) {
    navbarClasses.push('scrolled');
  }
  return (
    <>
      <header className={navbarClasses.join(" ")} ref={headerRef}>
        <div className="container">
          <div className="row">
            <div className="col logo-col">
              <a tabIndex={1} href="/" className="logo">
                <img className="mclosedlogo" src={Logo} alt="Shkary Sharks | Shkary Scenes" />
              </a>
            </div>
            <div className="col nav-col">
              <div className="mobile-menu-button">
                <button onClick={toggleMobileMenu}><MobileMenuIcon /></button>
              </div>
              <div className={isMobileMenuOpen ? 'desktop-menu mobile-menu-full-screen mobile-menu-visible' : 'desktop-menu mobile-menu-full-screen'}>
                <div className="mobile-menu-inner-header">
                  <div className="mobile-menu-inner-logo">
                    <a tabIndex={1} href="/" className="logo">
                      <img className="mopenlogo" src={LogoDark} alt="Shkary Sharks | Shkary Scenes" />
                    </a>
                  </div>
                  <div className="mobile-menu-button">
                    <button onClick={toggleMobileMenu}><MobileMenuCloseIcon /></button>
                  </div>
                </div>
                <nav className="nav-links">
                  <Link tabIndex={2} to="shkaryLore" smooth={false} spy={false} activeClass="active" onClick={() => { setIsMobileMenuOpen(false); }}>
                    Shkary lore
                  </Link>
                  {/* <Link tabIndex={3} to="utility" smooth={false} spy={false} activeClass="active" onClick={() => { setIsMobileMenuOpen(false); }}>
                    Utility
                  </Link> */}
                  <Link tabIndex={4} to="artworks" smooth={false} spy={false} activeClass="active" onClick={() => { setIsMobileMenuOpen(false); }}>
                    Artworks
                  </Link>
                  <Link tabIndex={5} to="partners" smooth={false} spy={false} activeClass="active" onClick={() => { setIsMobileMenuOpen(false); }}>
                    Partners
                  </Link>
                  <Link tabIndex={6} to="ourArtist" smooth={false} spy={false} activeClass="active" onClick={() => { setIsMobileMenuOpen(false); }}>
                    Our artist
                  </Link>
                  <Link tabIndex={7} to="shkaryThings" smooth={false} spy={false} activeClass="active" onClick={() => { setIsMobileMenuOpen(false); }}>
                    Shkary things
                  </Link>
                </nav>
                <ul className="nav-socials">
                  <li>
                    <a tabIndex={8}
                      href="https://discord.com/invite/eRXv4TZ8Pj"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <DiscordIcon />
                      <span className="social-icon-status status-online"></span>
                    </a>
                  </li>
                  <li>
                    <a tabIndex={9}
                      href="https://twitter.com/ShkarySharks"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TwitterIcon />
                      <span className="social-icon-status status-online"></span>
                    </a>
                  </li>
                  {/* <li>
                    <button tabIndex={10} className="header-mintBtn">
                      Mint
                      <span className="social-icon-status status-online"></span>
                    </button>
                  </li> */}
                </ul>
                <div className="mobile-menu-bottom-logo">
                  <img src="/assets/images/menuBGSuckerPunch.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <StyledGap></StyledGap>
    </>
  );
}

export default Header;
