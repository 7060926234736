import Logo from "../../logo.svg";
import { ReactComponent as TwitterIcon } from "../../assets/img/twitter.svg";
import { ReactComponent as DiscordIcon } from "../../assets/img/discord.svg";

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="footer-logo">
              <a tabIndex={1} href="/" className="logo">
                <img src={Logo} alt="" />
              </a>
            </div>
            <h2 className="text-center change-the-scene">
              We’re here to <span className="text-primary"> change the scene</span>
            </h2>

            <ul className="nav-socials">
              <li>
                <a
                  tabIndex={8}
                  href="https://discord.com/invite/eRXv4TZ8Pj"
                  target="_blank"
                  rel="noreferrer"
                >
                  <DiscordIcon />
                  <span className="social-icon-status status-online"></span>
                </a>
              </li>
              <li>
                <a
                  tabIndex={9}
                  href="https://twitter.com/ShkarySharks"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                  <span className="social-icon-status status-online"></span>
                </a>
              </li>
              {/* <li>
                <button tabIndex={10} className="header-mintBtn">
                  Mint
                  <span className="social-icon-status status-online"></span>
                </button>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
