import FadeInSection from "../../components/fadein";
function Artist() {
  return (
    <section className="artist" id="ourArtist">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="artist-wrapper">
              <div className="artist-image-area">
                <div className="artist-fernando">
                  <FadeInSection direction="left">
                    <img
                      src="/assets/images/fernando.png"
                      alt="Fernando Fernando"
                    />
                  </FadeInSection>
                </div>
              </div>
              <div className="artist-text-area">
                <h3>Meet our artist</h3>
                <h2>Fernando Nunes</h2>
                <p>
                  Fernando Nunes is an independent illustrator and designer who created all the stunning artwork you see here and across the Shkary brand. In his own words ‘I try to find the meaning of the unimaginable and exaggerated, I like to explore fantasy and things that are not yet known, taking inspiration in aspects and movements that Nature provides us.’
                </p>
                <div className="artist-bottom">
                  <div className="artist-buttons">
                    <div className="shkaryButton displayhidden">
                      <button tabIndex={16} target="_blank" rel="noreferrer">
                        Mint your scene
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="artistScenes">
        <div className="artistScenes-item artistScenes-crown">
          <FadeInSection>
            <img src="/assets/images/artistCrown.png" alt="" />
          </FadeInSection>
        </div>
      </div>
    </section>
  );
}

export default Artist;
