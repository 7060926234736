// import * as Scroll from "react-scroll";
import FadeInSection from "../../components/fadein";
function ShkaryLore() {
    // let Link = Scroll.Link;
    return (
        <section className="lore" id="shkaryLore">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="lore-wrapper">
                            <div className="lore-title-area">
                                <h2>Shkary <span className="text-line-br"> Scenes Lore</span></h2>
                                <div className="lore-bottom d-m-none">
                                    <div className="lore-buttons">
                                        <div className="shkaryButton displayhidden">
                                            <button tabIndex={13} target="_blank" rel="noreferrer">Mint your scene</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lore-text-area">
                                <p>Every 1,000 years or so, the Oceans pass from one celestial age to the next. Governed by forces beyond the powers of Kamohoali’i himself, this urges the shiver to migrate to new waters. The recent burning of the Shkary Sharks brought this migration forward by a considerable margin.</p>
                                <p>Lo, the Shkary Scenes are the records of what the Shkary Sharks encountered on their journey - some may have encountered peril; others great beauty. They have all been captured lovingly and carefully by the chosen aesthete of the Shkary Sharks - and who else could it be than the most beloved and kindest shark of all? Cello!</p>
                                <p>Each scene depicts an important event or place in the Great Migration; revered by Kamohoali’i and his followers for all time. They are made by the artists of the shiver and collected by Cello for the shiver to reflect upon and enjoy.</p>
                                <ul className="lore-benefits">
                                    <li>
                                        <img src="/assets/images/traitsfin.png" alt="" />
                                        115+ traits
                                    </li>
                                    <li>
                                        <img src="/assets/images/doxxedfin.png" alt="" />
                                        Doxxed team
                                    </li>
                                    <li>
                                        <img src="/assets/images/reliablefin.png" alt="" />
                                        Reliable launchpad
                                    </li>
                                    <li>
                                        <img src="/assets/images/dedicatedfin.png" alt="" />
                                        Dedicated artist
                                    </li>
                                </ul>
                                <div className="lore-bottom d-d-none">
                                    <div className="lore-buttons">
                                        <div className="shkaryButton displayhidden">
                                            <button tabIndex={13} target="_blank" rel="noreferrer">Mint your scene</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="loreScenes">
                <div className="loreScenes-item loreScenes-shark">
                    <FadeInSection direction="left"><img src="/assets/images/loreShark.png" alt="" /></FadeInSection>
                </div>
                <div className="loreScenes-item loreScenes-octo">
                    <FadeInSection direction="right"><img src="/assets/images/loreOcto.png" alt="" /></FadeInSection>
                </div>
            </div>
        </section>
    );
}

export default ShkaryLore;