function Arrow(props) {
    const disabeld = props.disabled ? " arrow--disabled" : "";
    const fillcolor = props.fill ? props.fill : "#04017D";
    const mode = props.mode ? props.mode : "svg";
    return (
        <>
            {mode === 'div' ? (
                <div
                    onClick={props.onClick}
                    className={`arrowBtn ${props.left ? "arrowBtn--left" : "arrowBtn--right"
                        } ${disabeld}`}>
                    <svg
                        className={`arrowIcon ${props.left ? "arrow--left" : "arrow--right"
                            }`}
                        width="16"
                        height="6"
                        viewBox="0 0 16 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        {props.left && (
                            <path d="M0 3L5 5.88675L5 0.113248L0 3ZM16 2.5L4.5 2.5L4.5 3.5L16 3.5L16 2.5Z" fill={fillcolor} />
                        )}
                        {!props.left && (
                            <path d="M16 3L11 0.113249V5.88675L16 3ZM0 3.5H11.5V2.5H0V3.5Z" fill={fillcolor} />
                        )}
                    </svg>
                </div>
            ) : (
                <svg
                    onClick={props.onClick}
                    className={`arrowIcon ${props.left ? "arrow--left" : "arrow--right"
                        } ${disabeld}`}
                    width="16"
                    height="6"
                    viewBox="0 0 16 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    {props.left && (
                        <path d="M0 3L5 5.88675L5 0.113248L0 3ZM16 2.5L4.5 2.5L4.5 3.5L16 3.5L16 2.5Z" fill={fillcolor} />
                    )}
                    {!props.left && (
                        <path d="M16 3L11 0.113249V5.88675L16 3ZM0 3.5H11.5V2.5H0V3.5Z" fill={fillcolor} />
                    )}
                </svg>
            )}
        </>
    )
}

export default Arrow;