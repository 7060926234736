import FadeInSection from "../../components/fadein";
function LaunchPad() {
  return (
    <section className="launchpad" id="partners">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="launchpad-wrapper">
              <div className="launchpad-text-area">
                <h2>Launchpad Partner</h2>
                <p>
                  We are pleased to be partnering with the incredible and reputable team at XLabs, formerly Xin Dragons. This team has proven themselves time and again they are more than capable in their technical abilities and we have no doubts they are the right team to lead Shkary Scenes to a successful mint.
                </p>
                <div className="launchpad-bottom">
                  <div className="launchpad-buttons">
                    <div className="shkaryButton displayhidden">
                      <button tabIndex={15} target="_blank" rel="noreferrer">
                        Mint your scene
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="launchpad-image-area">
                <div className="launchpad-dragon">
                  <FadeInSection direction="right">
                    <img src="/assets/images/dragon.png" alt="" />
                  </FadeInSection>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="launchpadScenes">
        <div className="launchpadScenes-item launchpadScenes-eyes">
          <FadeInSection>
            <img src="/assets/images/launchpadEyes.png" alt="" />
          </FadeInSection>
        </div>
      </div>
    </section>
  );
}

export default LaunchPad;
