import React, { useState } from "react";
import FadeInSection from "../../components/fadein";
import Arrow from "../../components/arrow";
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
function Artwork() {
  const images = [
    "/assets/images/LEGENDARY_7.jpeg",
    "/assets/images/LEGENDARY_9.jpeg",
    "/assets/images/ss-crown.jpeg",
    "/assets/images/ss-fav-traits.jpeg",
    "/assets/images/ss-minefield.jpeg",
    "/assets/images/ss-rainbow.jpeg",
    "/assets/images/ss-sucker.jpeg",
    "/assets/images/ss-whale.jpeg",
    // "/assets/images/artwork9.png",
  ]
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [details, setDetails] = React.useState(null);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    loop: false,
    mode: "free-snap",
    defaultAnimation: {
      duration: '1500'
    },
    slides: {
      origin: 'center',
      perView: '1.23',
      spacing: '0'
    },
    breakpoints: {
      '(min-width: 601px)': {
        slides: {
          perView: '1',
          spacing: '40'
        },
      },
    },
    detailsChanged(s) {
      setDetails(s.track.details)
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  });
  function scaleStyle(idx) {
    if (!details) return {}
    const slide = details.slides[idx]
    const scale_size = 0.2
    const scale = 1 - (scale_size - scale_size * slide.portion)
    return {
      transform: `scale(${scale})`,
      WebkitTransform: `scale(${scale})`,
    }
  }
  return (
    <section className="artwork" id="artworks">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 className="artwork-title text-center">Artwork examples</h2>
            <div className="artworkExamples">
              <div className="artworkExWrapper">
                <div ref={sliderRef} className="keen-slider">
                  {images.map((src, idx) => (
                    <div key={idx} className="keen-slider__slide zoom-out__slide">
                      <div className="artworkDisplay" style={scaleStyle(idx)}>
                        <img src={src} alt="" />
                        <img src={src.replace('artwork', 'artworkM')} alt="" />
                      </div>
                    </div>
                  ))}
                  <div className="artworkNav">
                    {loaded && instanceRef.current && (
                      <>
                        <Arrow
                          left
                          mode="div"
                          fill="currentColor"
                          onClick={(e) =>
                            e.stopPropagation() || instanceRef.current?.prev()
                          }
                          disabled={currentSlide === 0}
                        />
                        <Arrow
                          right
                          mode="div"
                          fill="currentColor"
                          onClick={(e) =>
                            e.stopPropagation() || instanceRef.current?.next()
                          }
                          disabled={
                            currentSlide ===
                            instanceRef.current.track.details.slides.length - 1
                          }
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="artworkPagination">
                  {loaded && instanceRef.current && (
                    <div className="dots">
                      {[
                        ...Array(instanceRef.current.track.details.slides.length).keys(),
                      ].map((idx) => {
                        return (
                          <button
                            key={idx}
                            onClick={() => {
                              instanceRef.current?.moveToIdx(idx)
                            }}
                            className={"dot" + (currentSlide === idx ? " active" : "")}
                          ></button>
                        )
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="artworkScenes">
        <div className="artworkScenes-item artworkScenes-one">
          <FadeInSection direction="left">
            <img src="/assets/images/artworkScene1.png" alt="" />
          </FadeInSection>
        </div>
        <div className="artworkScenes-item artworkScenes-two">
          <FadeInSection direction="right">
            <img src="/assets/images/artworkScene2.png" alt="" />
          </FadeInSection>
        </div>
      </div>
    </section >
  );
}

export default Artwork;
