import FadeInSection from "../../components/fadein";
import { Link } from "react-scroll";
function Intro() {
  return (
    <section className="intro" id="intro">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="intro-wrapper">
              <div className="intro-text-area">
                <h1>We’re here to <span className="text-primary"> change the scene</span></h1>
                <p>3,800 unique, banner-sized NFTs by our resident artist Fernando Nunes</p>
                <div className="intro-bottom">
                  <div className="intro-buttons">
                    <div className="shkaryButton">
                      <Link tabIndex={11} to="artworks" smooth={false} spy={false} activeClass="active" onClick={() => { }}>See the artwork</Link>
                    </div>
                    <div className="shkaryButton intro-meet-button">
                      <Link tabIndex={12} to="ourArtist" smooth={false} spy={false} activeClass="active" onClick={() => { }}>Meet our artist</Link>
                    </div>
                  </div>
                  <div className="intro-spaceship">
                    <FadeInSection direction="right"><img src="/assets/images/spaceships.png" alt="" /></FadeInSection>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Intro;