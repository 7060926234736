import FadeInSection from "../../components/fadein";
function ShkaryThings() {
  return (
    <section className="shkarythings" id="shkaryThings">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 className="shkarythings-title text-center">
              Other Shkary Things
            </h2>
            <div className="shkarythings-items">
              <div className="shkarythings-item">
                <h3>Shkary Sharks</h3>
                <p>Buy our sharks from Magic Eden</p>
                <div className="shkarythings-item-buttons">
                  <div className="shkaryButton shkaryThingsButton intro-meet-button">
                    <a href="https://magiceden.io/marketplace/shkary_sharks" tabIndex={17} target="_blank" rel="noreferrer">
                      Buy Sharks
                    </a>
                  </div>
                </div>
                <div className="shkarythings-item-bottom">
                  <div className="shkarythings-item-image">
                    <FadeInSection>
                      <img src="/assets/images/shkarythings1.png" alt="" />
                    </FadeInSection>
                  </div>
                </div>
              </div>
              <div className="shkarythings-item">
                <h3>Shkary Casino</h3>
                <p>Try your luck at the Shkary slots</p>
                <div className="shkarythings-item-buttons">
                  <div className="shkaryButton shkaryThingsButton">
                    <a href="https://casino.shkarydao.io/" tabIndex={18} target="_blank" rel="noreferrer">
                      Play the slots
                    </a>
                  </div>
                </div>
                <div className="shkarythings-item-bottom">
                  <div className="shkarythings-item-image shkarythings-casino-image">
                    <FadeInSection>
                      <img src="/assets/images/shkarythings2.png" alt="" />
                    </FadeInSection>
                  </div>
                </div>
              </div>
              <div className="shkarythings-item">
                <h3>Sharketplace</h3>
                <p>Raffles and auctions up for grabs</p>
                <div className="shkarythings-item-buttons">
                  <div className="shkaryButton shkaryThingsButton intro-meet-button">
                    <a href="https://sharketplace.shkarydao.io/" tabIndex={19} target="_blank" rel="noreferrer">
                      View raffles
                    </a>
                  </div>
                </div>
                <div className="shkarythings-item-bottom">
                  <div className="shkarythings-item-image">
                    <FadeInSection>
                      <img src="/assets/images/shkarythings3.png" alt="" />
                    </FadeInSection>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ShkaryThings;
